<template>
    <ContentWrapper>
        <breadcrumb/>

        <b-container>

            <div id="category-selector" class="card card-default animated-fast fadeInDown">
                <div class="card-body">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-xl-4">
                            <a class="category-selection" href="https://swiss.cannerald.shop/7-">
                                <img src="../assets/category-selector/flowers.png"/>
                                <span>Flowers</span>
                                <i><img src="../assets/icons/Cannerald_Shop_Icon-Cannabis.svg"/></i>
                            </a>
                        </div>

                        <div class="col-lg-6 col-xl-4">
                            <a class="category-selection" href="https://cannerald.shop/12-">
                                <img src="../assets/category-selector/vape.png"/>
                                <span>Vape</span>
                                <i><img src="../assets/icons/Cannerald_Shop_Icon-Vape.svg"/></i>
                            </a>
                            <a class="category-selection" href="https://swiss.cannerald.shop/11-">
                                <img src="../assets/category-selector/rolls.png"/>
                                <span>Pre-Rolls</span>
                                <i><img src="../assets/icons/Cannerald_Shop_Icon-Pre-Roll.svg"/></i>
                            </a>
                        </div>

                        <div class="col-lg-12 col-xl-4">
                            <a class="category-selection" href="https://cannerald.shop/8-">
                                <img src="../assets/category-selector/oils.png"/>
                                <span>Oils & Sprays</span>
                                <i><img src="../assets/icons/Cannerald_Shop_Icon-Öl-Tropfen.svg"/></i>
                            </a>

                            <div class="row">
                                <div class="col-sm-6">
                                    <a class="category-selection" href="https://cannerald.shop/9-">
                                        <img src="../assets/category-selector/merch.png"/>
                                        <span>Merch</span>
                                        <i><img src="../assets/icons/Cannerald_Shop_Icon-T-Shirt.svg"/></i>
                                    </a>
                                </div>
                                <div class="col-sm-6">
                                    <a class="category-selection" href="https://cannerald.shop/10-">
                                        <img src="../assets/category-selector/additional.png"/>
                                        <span></span>
                                        <i><img src="../assets/icons/Cannerald_Shop_Icon-Zubehör.svg"/></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <b-row class="class justify-content-center mb-3" v-if="dashboard.balances">
                <b-col sm="5" class="order-sm-first">
                    <wallet-balance-cannabis class="animated-fast fadeInLeft" :balance="dashboard.balances.cbd"></wallet-balance-cannabis>
                </b-col>
                <b-col sm="5">
                    <wallet-balance-fiat class="animated-fast fadeInRight" :balance="dashboard.balances.fiat"></wallet-balance-fiat>
                </b-col>

              <p>Info: 1g entspricht 1 EUR im Shop</p>
            </b-row>

        </b-container>

    </ContentWrapper>
</template>
<script>


    import AccordionCard from "../../Growing/components/AccordionCard";
    import WalletBalanceFiat from '../../Wallet/components/BalanceFiat';
    import WalletBalanceCannabis from '../../Wallet/components/BalanceCannabis';

    export default {
        components: {WalletBalanceCannabis, WalletBalanceFiat, AccordionCard},
        data() {
            return {
                dashboard: {
                    discount_codes: [],
                    exchange_rate_cbd: 0
                }
            }
        },
        beforeCreate() {
            this.$api.get('shop/dashboard').then(response => {
                this.dashboard = response.data;
            })
        },
        methods: {},
    }
</script>